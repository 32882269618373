import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import dateService from 'APP/packages/date';
import { useTranslation } from 'APP/packages/translations';
import LogoIcon from 'ICONS/logo-new-year.svg';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';

import { AboutContentItem } from '../AboutContentItem/AboutContentItem';
import { useAboutContentPresenter } from './AboutContent.presenter';
import styles from './AboutContent.styles.m.css';

export const AboutContent = observer(() => {
  const { t } = useTranslation();
  const presenter = useAboutContentPresenter();

  return (
    <div className={classNames(styles.container, customScrollStyles.container)}>
      <LogoIcon className={styles.logo} />
      <p className={styles.title}>{t('about_gem4me_title')}</p>
      <p className={styles.version}>{t('about_version').replace('{0}', presenter.version)}</p>
      {presenter.items.map((item) => (
        <AboutContentItem {...item} />
      ))}
      <p className={styles.copyright}>
        {t('about_copyright').replace('{0}', dateService.now().getFullYear().toString())}
      </p>
    </div>
  );
});
